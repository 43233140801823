import React from "react";

export const HelpCategoryPageTemplate = () => (
    <div></div>
);

const HelpCategoryPage = () => (
    <HelpCategoryPageTemplate></HelpCategoryPageTemplate>
)

export default HelpCategoryPage;